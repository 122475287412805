.main
  background-color: #202020
  width: 1040px
  padding: 20px
  @media screen and (max-width: 1350px)
    padding: 0
    margin: 0 auto
  @media screen and (max-width: 1000px)
    width: 100%
  .content
    display: flex
    flex-direction: column
    .charts

      @media screen and (max-width: 1000px)
          margin-bottom: 60px
    .hiddenChart
      position: sticky
      top: -100px
      z-index: 10
