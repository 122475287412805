.main
  //margin-top: 40px
  padding-top: 40px
  .checkbox
    display: grid
    grid-template-columns: 160px
    grid-template-rows: auto
    grid-column-gap: 15px
    align-items: center
    .item
      margin-bottom: 10px
      .label
        line-height: 1.4


