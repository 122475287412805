.main
  max-width: 1400px
  @media screen and (max-width: 600px)
    width: 100vw
  margin: 0 auto
  position: relative
  .logo
    display: flex
    justify-content: flex-end
  .divider
    background: linear-gradient(148.81deg, rgba(2, 145, 237, 1) -3.59%, rgba(125, 195, 93, 1) 20.22%, rgba(241, 232, 18, 1) 36.91%, rgba(236, 230, 17, .8) 52.26%, rgba(134, 196, 63, .4) 69.59%, rgba(28, 169, 77, 0) 87.62%)
    margin-top: 55px
    margin-bottom: 20px
    width: 100%
    height: 6px
    border-radius: 5px
    position: absolute
    top: 30px
    @media screen and (max-width: 1350px)
     top: 0
  .form
    display: flex
    justify-content: center
    align-items: center
    height: 80vh
    .item
      width: 40vw
      @media screen and (max-width: 800px)
        width: 80vw
      .button
        width: 100%

