.topSection
  display: flex
  justify-content: space-between
  align-items: center
  position: relative
  .buttons

    margin-bottom: 20px
    display: flex

    @media screen and (max-width: 1350px)
      position: static
      margin-top: 20px
      margin-left: 20px
    .link
      font-weight: 600
      font-size: 18px
      display: inline-block
      position: relative
      padding: 3px 0
      white-space: nowrap
      text-decoration: none
      color: white
      margin-right: 20px
      &:after
        content: ''
        position: absolute
        width: 100%
        height: 2px
        bottom: 0
        left: 0
        background-color: #08A652
        transform: scaleX(0)
        transform-origin: bottom right
        transition: transform 0.3s

      &:hover
        color: #08A652

        &:after
          transform-origin: bottom left
          transform: scaleX(1)
    .active
      color: #f1e812
  .logo
    margin-right: 20px
