.settings
  width: 320px
  height: 500px
  position: sticky
  top: 20px
  z-index: 100
  display: flex
  flex-direction: column
  gap: 20px
  color: #FFFFFF
  .checkBoxes
    .title
      color: #FFFFFF
      margin-bottom: 20px
      font-weight: bold
    .items
      display: flex
      flex-direction: column
      gap: 10px