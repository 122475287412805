.switch
  margin-top: 10px
  display: flex

  flex-direction: column
  align-items: flex-start
  gap: 5px
  @media screen and (max-width: 1000px)
    margin-top: 25px
.tableOption
  display: flex
  align-items: center
  margin-top: 5px
  @media screen and (max-width: 1000px)
    display: block
    position: static
    margin-top: 25px
    margin-bottom: 8px