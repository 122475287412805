.main
  .topBlock
    position: relative
    display: flex
    .picker
      position: relative
      top: -14px
    .titleBlock
      position: absolute
      width: calc(100% - 370px)
      z-index: 100
      left: 370px
      top: -50px
      .title
        color: #FFFFFF
        font-size: 34px
        font-weight: 600
      .divider
        background: linear-gradient(148.81deg, rgba(2, 145, 237, 1) -3.59%, rgba(125, 195, 93, 1) 20.22%, rgba(241, 232, 18, 1) 36.91%, rgba(236, 230, 17, .8) 52.26%, rgba(134, 196, 63, .4) 69.59%, rgba(28, 169, 77, 0) 87.62%)
        margin-top: 18px
        margin-bottom: 20px
        width: 100%
        height: 6px
        border-radius: 5px
        max-width: 1060px
      @media screen and (max-width: 1350px)

        top: -80px
  .settings
    position: sticky
    top: 0
    z-index: 10
    background-color: #202020
    .topBlockSettings
      display: flex
      justify-content: space-between
      align-items: center
    .bottomBlockSettings
      display: flex
      justify-content: space-between
      align-items: center
      .buttons
        display: flex
        align-items: center
        .numb
          color: #08A652
  .content
    color: #FFFFFF
    .tableHead
      height: 50px
      display: flex
      justify-content: space-between
      margin-left: 40px
      position: sticky
      top: 60px
      z-index: 10
      .tableItem
        display: flex
        justify-content: center
        align-items: center
        background-color: #515151
        border: 1px solid #202020
        &:first-child
          border-top-left-radius: 5px
          border-bottom-left-radius: 5px
        &:last-child
          border-top-right-radius: 5px
          border-bottom-right-radius: 5px
    .weekBlock
      .platformBlock
        .categoriesBlock
          display: flex
          justify-content: space-between
          .categoriesItem
