.buttons
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  margin-top: 5px
  @media screen and (max-width: 950px)
    margin-top: 40px
  @media screen and (max-width: 765px)
    height: 80px
  @media screen and (max-width: 512px)
    height: 123px
  .button
    height: 36px
    padding-right: 24px
    padding-left: 24px
    max-width: 500px
    min-width: 150px

    @media screen and (max-width: 1000px)
      padding-right: 11px
      padding-left: 11px

      @media screen and (max-width: 765px)
        margin: 0 2px
        flex-grow: 1
        //&:last-child
          margin-left: 85px