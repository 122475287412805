.item
  border: 1px solid #FFFFFF
  padding: 0 10px
  font-weight: bold
  border-radius: 5px
  max-height: 20px
  overflow: hidden
  word-break: break-word
  &:hover
    border: 2px solid #FFFFFF
  .itemText
    font-size: 12px
.popoverContent
  width: 300px
  z-index: 10
  word-break: break-word
  text-align: justify
  color: #FFFFFF
  .title
    font-weight: bold
