.main
  color: #F5F5F5
  height: 700px
  display: flex
  justify-content: center
  align-items: center
  font-size: 24px
  text-align: center
//@media screen and (max-width: 950px)
  height: auto
.price
  color: #F5F5F5
  height: 450px
  display: flex
  justify-content: center
  align-items: center
  font-size: 24px
  text-align: center
