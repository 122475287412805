.main
  background-color: #202020
  position: relative
  .title
    position: absolute
    width: 100%
    top: -100px
    .divider
      background: linear-gradient(148.81deg, rgba(2, 145, 237, 1) -3.59%, rgba(125, 195, 93, 1) 20.22%, rgba(241, 232, 18, 1) 36.91%, rgba(236, 230, 17, .8) 52.26%, rgba(134, 196, 63, .4) 69.59%, rgba(28, 169, 77, 0) 87.62%)
      margin-top: 18px
      margin-bottom: 20px
      width: 100%
      height: 6px
      border-radius: 5px
    @media screen and (max-width: 1350px)
      position: inherit
      top: 0
  .chart
    //height: 700px
    @media screen and (max-width: 950px)
      height: auto
    margin-top: 30px






