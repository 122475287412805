.main
  width: 1040px
  margin-right: 20px
  .title
    margin-left: 20px
    margin-bottom: 10px
    color: #FFFFFF
    font-size: 32px
    font-weight: bold
  .tableHead
    height: 50px
    display: flex
    justify-content: space-between
    margin-left: 40px
    position: sticky
    top: 0px
    z-index: 10
    .tableItem
      display: flex
      justify-content: center
      align-items: center
      background-color: #515151
      border: 1px solid #202020
      color: #FFFFFF
      padding-left: 25px
      &:first-child
        border-top-left-radius: 5px
        border-bottom-left-radius: 5px
      &:last-child
        border-top-right-radius: 5px
        border-bottom-right-radius: 5px