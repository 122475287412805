.navigation
  display: flex
  justify-content: space-between
  align-items: center
  @media screen and (max-width: 680px)
    flex-direction: column
  .button
    margin-bottom: 10px
.charts
  position: relative
  margin-top: 10px
  .block
    width: 105px
    height: 20px
    background-color: #202020
    position: absolute
    right: 10px
    z-index: 20