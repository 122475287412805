
  .tableRow
    break-inside: avoid-page
    border-radius: 5px
    display: grid
    grid-template-columns: 1.2fr 1fr 0.8fr .9fr .9fr
    padding: 3px
    margin-bottom: 4px
    @media screen and (max-width: 765px)
      grid-template-columns: 1fr
      grid-template-rows: 1.5fr 1fr 1fr 1fr 1fr 1fr
      padding: 10px 20px

    .tableItem
      display: flex
      justify-content: center
      align-items: center
      padding-right: 10px
      hyphens: auto
      text-align: center
      &:first-child
        justify-content: flex-start
        font-weight: 600
        margin-left: 10px
        word-break: break-word
        text-align: left
      @media screen and (max-width: 765px)
        justify-content: space-between
      @media screen and (max-width: 765px)
      .titleItem
        display: none
        @media screen and (max-width: 765px)
          display: inline

