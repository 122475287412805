.main
  //max-height: 100px
  width: 600px
  padding: 20px
  position: relative
  .button
    position: absolute
    top: -32px
    right: 30px
  .banners
    .item
      width: 100%
      height: 100%
      object-fit: contain



