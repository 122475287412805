.tableTitle
  border-radius: 5px
  display: grid
  text-align: center
  background-color: #F5F5F5
  grid-template-columns: 1.2fr 1.2fr 1fr 0.8fr .9fr .9fr
  padding: 3px
  margin-bottom: 4px
  @media screen and (max-width: 765px)
    display: none
  .tableTop
    cursor: pointer
    color: #202020
    display: flex
    justify-content: center
    align-items: center
    height: 32px
    font-weight: 600
    &:hover
      color: dimgrey
      border-radius: 5px
.mps
  grid-template-columns: 1.2fr 1fr 0.8fr .9fr .9fr
