.main
  display: flex
  justify-content: space-between
  @media screen and (max-width: 999px)
    flex-direction: column
  .charts
    width: 650px
    height: 650px
    @media screen and (max-width: 999px)
      width: 100%
    .chart

  .info
    color: #F5F5F5
    width: 300px
    @media screen and (max-width: 999px)
      //position: absolute
      //top: 1400px
      margin-top: 300px
      width: 90%
      @media screen and (max-width: 765px)
        //margin-top: 80%
      @media screen and (max-width: 512px)
        margin-top: 350px