.main
  position: relative
  .titleBlock
    position: absolute
    z-index: 100
    width: 70%
    left: 370px
    top: -110px
    .title
      color: #FFFFFF
      font-size: 34px
      font-weight: 600
      height: 40px
      overflow: hidden
    .divider
      background: linear-gradient(148.81deg, rgba(2, 145, 237, 1) -3.59%, rgba(125, 195, 93, 1) 20.22%, rgba(241, 232, 18, 1) 36.91%, rgba(236, 230, 17, .8) 52.26%, rgba(134, 196, 63, .4) 69.59%, rgba(28, 169, 77, 0) 87.62%)
      margin-top: 18px
      margin-bottom: 20px
      width: 100%
      height: 6px
      border-radius: 5px
    @media screen and (max-width: 1350px)

      top: -80px
  .settingsBlock
    display: flex
    justify-content: space-between
    margin-top: 45px
    margin-bottom: 30px