.main
  //margin-top: 50px
  padding-top: 40px
  .checkboxGroup
    .checkbox
      display: grid
      margin-left: 188px
      grid-template-columns: 180px
      grid-template-rows: auto
      grid-column-gap: 15px
      align-items: center
      .item
        margin-bottom: 10px
        .label
          //word-wrap: break-word
          line-height: 1.4


