.main
  width: 100%
  background-color: purple
  border: 1px solid white
  border-radius: 5px
  text-align: justify
  color: #FFFFFF
  overflow: hidden
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out
  max-height: 50px
  padding: 0 10px
  cursor: pointer
  word-break: break-word
  .date
    font-weight: bold
  .title
    font-weight: bold
  .content
  .links


.hidden
  max-height: 50px
  padding: 0 10px

.expanded
  max-height: 1000px
  padding: 10px

