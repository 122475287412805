/*@import url(https://db.onlinewebfonts.com/c/c68e21ad1a10803b1ef491fd0392b067?family=SB+Sans+Text);*/
*,
*::before,
*::after {
    box-sizing: border-box;
}
ul,
ol {
    padding: 0;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
h7,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
img,
dd {
    margin: 0;
}
body {
  margin: 0;
  font-family: SB Sans Text, PT Sans Caption,
  Roboto, sans-serif, 'Ubuntu', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Helvetica Neue';

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #202020;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #08A652 !important;
}



/*.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-panel-container, .ant-picker-footer {
  width: 300px !important;
}*/

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}


.ant-table-body {
  overflow-y: auto !important;
}

.ant-table-row {
  font-size: 14px;
  padding: 2px !important;
  background-color: #202020;
  color: #F5F5F5;
}

.ant-table-row .ant-table-row-level-0 {
  width: 8px !important;
  padding-top: 8px !important;
}

.ant-table-column {
  align-content: center;
}


:where(.css-dev-only-do-not-override-1fkvw7a).ant-checkbox-wrapper span {
    display: block;
}
@media screen and (max-width: 411px) {
  :where(.css-dev-only-do-not-override-1fkvw7a).ant-checkbox-wrapper {
      font-size: 14px;
  }
}

.ant-checkbox-group-item {
  margin-right: 8px;
  margin-bottom: 10px;
}

.ant-card .ant-card-body {
  padding: 24px 0;
  border-radius: 0 0 2px 2px;
}

.apexcharts-datalabels {
    color: #202020;
}

.slick-dots li button:before {

  color: #08A652 !important;

}
.slick-prev:before, .slick-next:before {
  color:  #08A652 !important;
}

.apexcharts-tooltip {
  text-wrap: inherit !important;
  min-width: 200px;
}

/*____________________________________________ToolTip__________________________________________________*/
.toolTip {
  padding: 10px;
  overflow: auto !important;
  white-space: pre-wrap !important;


  max-width: 500px;
}
.toolTip__head {
  font-size: 16px;
  font-weight: 700;
}
.toolTip__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  margin-top: 10px;
}

.toolTip__item {
  font-weight: 700;
}

/*_____________________________________________________________________________________________________*/

.ant-table-wrapper .ant-table {
    scrollbar-color: #8c8c8c #202020 ;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background-color: #202020 !important; /* Цвет по умолчанию для всех фиксированных столбцов */
}