.main
  //margin-top: 60px
  max-width: 320px
  display: flex
  flex-direction: column
  @media screen and (max-width: 1350px)
    width: 728px
    flex-direction: row
    justify-content: space-between
    margin: 0 auto
  @media screen and (max-width: 765px)
    width: 300px
    flex-direction: column
    justify-content: center
    margin-bottom: 40px
  .checkboxBlock
    max-width: 360px
    .items
      //margin-top: 6px
      //height: 400px
      //min-height: 500px
      @media screen and (max-width: 765px)
        height: 500px
      .boxes
        display: flex
        justify-content: space-between
        gap: 20px
  .periodBlock
    .title
      width: 100%
      margin-top: 36px
      margin-bottom: 22px
      color: #F5F5F5
      font-weight: 600
      display: flex
      justify-content: space-between
      align-items: center
      @media screen and (max-width: 1350px)
        margin-top: 0
      @media screen and (max-width: 765px)
        margin-top: 70px
    .price
      width: 320px
      .cards
        margin-top: 10px
.hiddenChart
  position: sticky
  top: 0
  z-index: 10
